import React, { Component } from 'react';
import DataTable from './components/DataTable';
import Button from './components/Button';
import Error from './components/Error';
import './css/bootstrap.min.css';
import logo from './images/logo.png';
import Firebase from './api/Firebase';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: null,
      loggedIn: false,
      downloadLink: ''
    }
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.handlePassword = this.handlePassword.bind(this)
  }
  componentDidMount() {
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({loggedIn: true})
      } else {
        this.setState({loggedIn: false})
      }
    })
  }
  render() {
    if (this.state.loggedIn) {
      return (
        <div className="container-fluid" style={styles.container}>
          <header className="row" style={styles.header}>
            <div className="col-md-6">
              <h1 style={styles.siteName}><img src={logo} style={styles.logo} alt="Faithful Homes" /> Billing</h1>
            </div>
            <div className="col-md-6 text-right">
              <a href="#" onClick={this.logout} className="btn">Log Out</a>
            </div>
          </header>
          <div style={styles.container}>
            <DataTable></DataTable>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid" style={styles.container}>
          <header className="row" style={styles.header}>
            <h1 style={styles.siteName}><img src={logo} style={styles.logo} alt="Faithful Homes" /> Billing</h1>
          </header>
          <div style={styles.container} className="container">
            <div className="row">
              <form onSubmit={this.login} className="col-md-4 col-md-offset-4">
                <h2>Log In</h2>
                <p><label>Email:</label><br /><input type="email" value={this.state.email} onChange={this.handleEmail} style={styles.input} /></p>
                <p><label>Password:</label><br /><input type="password" value={this.state.password} onChange={this.handlePassword} style={styles.input} /></p>
                <div className="row" style={styles.flexAlignCenter}>
                  <div className="col-md-6">
                    <Button form={true}>Log in</Button>
                  </div>
                  <div className="col-md-6 text-right">
                    <a href="#">Password help</a>
                  </div>
                </div>
                <br />
                <Error text={this.state.error} />
              </form>
            </div>
          </div>
        </div>
      )
    }
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handlePassword(event) {
    this.setState({ password: event.target.value });
  }
  login(e) {
    Firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(data => {
      this.setState({loggedIn: true})
      this.setState({email: ''})
      this.setState({password: ''})
    }).catch(error => {
      this.setState({error: error.message})
    });
    e.preventDefault()
    return false;
  }
  logout() {
    Firebase.auth().signOut().then(data => {
      this.state.loggedIn = false
    }).catch(error => {
      alert('Something went wrong.')
    });
  }
}

var styles = {
  container: {
    background: '#F5F6FA',
    paddingBottom: 20,
    minHeight: '100vh'
  },
  header: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px #dbdbdb solid',
    background: "#fff",
    marginBottom: 40
  },
  mainRow: {
    paddingBottom: 40
  },
  logo: {
    width: 64,
    verticalAlign: 'middle',
    marginRight: 20,
    marginLeft: 20
  },
  siteName: {
    display: 'flex',
    marginTop: 0,
    alignItems: 'center',
    fontWeight: 300,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    border: '0',
    font: 'inherit',
    background: '#fff',
    padding: '1rem',
    display: 'block',
    width: '100%'
  }
}

export default App;
import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class WeekPicker extends Component {
  constructor(props) {
    var moment = require('moment');
    super(props)
    this.state = {
      startDate: moment().subtract('days', 7),
      endDate: moment(),
      focusedInput: null
    }
  }
  
  render() {
    return (
      <DateRangePicker
        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
        startDateId="start_date" // PropTypes.string.isRequired,
        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
        endDateId="end_date" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }, this.afterStateChange); this.setDates(); }} // PropTypes.func.isRequired,
        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        isOutsideRange={() => false}
        minimumNights={7} />
    );
  }

  setDates() {
    window.setTimeout(() => {
      if (this.state.startDate && this.state.endDate) {
        var startDate = this.state.startDate
        var endDate = this.state.endDate
        this.props.callbackFromParent({ startDate, endDate })
      }
    }, 500)
  }
}

export default WeekPicker;
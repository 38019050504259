import firebase from 'firebase'
const config = {
  apiKey: "AIzaSyBhsvumsYB0HnjyBUEo3ao2O6kVgMJaBNE",
  authDomain: "faithful-homes.firebaseapp.com",
  databaseURL: "https://faithful-homes.firebaseio.com",
  projectId: "faithful-homes",
  storageBucket: "faithful-homes.appspot.com",
  messagingSenderId: "131741227082"
};
firebase.initializeApp(config);
export default firebase;
import React, { Component } from 'react';

class Button extends Component {
  render() {
    if (this.props.form) {
      return (
        <button type="submit" onClick={this.props.onClick} style={styles.container}>{this.props.children}</button>
      )
    } else {
      return (
        <a href={this.props.link} style={styles.container}>{this.props.children}</a>
      )
    }
  }
}

var styles = {
  container: {
    display: 'inline-flex',
    padding: '10px 22px',
    borderRadius: 3,
    background: '#4f7ab7',
    color: '#fff',
    boxShadow: 'none',
    WebkitAppearance: 'none',
    border: 0
  }
}

export default Button;
import React, { Component } from 'react';

class Error extends Component {
  render() {
    var classes = ''
    if (this.props.text) {
      classes = 'alert alert-danger'
    } else {
      classes = ''
    }
    return (
    <div className={classes} role="alert">
      {this.props.text}
    </div>
    )
  }
}

export default Error;
import React, { Component } from 'react';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import GoogleSheetsData from '../api/GoogleSheets'
import Button from './Button';
import WeekPicker from './WeekPicker';
import Firebase from '../api/Firebase';
import update from 'immutability-helper';

class DataTable extends Component {
  constructor(props) {
    super(props);
      
    this.state = { selected: {}, selectAll: 0, exportFields: [], db: {}, data: [], allRows: [] };

    this.toggleRow = this.toggleRow.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.downloadTxtFile = this.downloadTxtFile.bind(this)
  }

  componentDidMount() {
    this.getMedicaidNumbers()
  }

  parseName(name) {
    var numbersAtEnd = /\d{3}$/
    if (name.match(numbersAtEnd)) {
      name = name.substr(0, name.length - 4)
    }
    return name
  }

  getRows(id) {
    GoogleSheetsData.then(data => {
      var moment = require('moment');
      var sheet1 = data.valueRanges[0].values
      // var sheet2 = data.valueRanges[1].values
      // var sheet3 = data.valueRanges[2].values
      // var sheet4 = data.valueRanges[3].values

      var batchRowValues = sheet1//.concat(sheet2, sheet3, sheet4)

      const rows = []
      const exportFields = []
      for (let i = 0; i < batchRowValues.length; i++) {
        var d = new Date()
        var date = d.getDate()

        var timeColumn = batchRowValues[i][28]
        var serviceTimestamp = moment(timeColumn)
        var serviceMonthYear = serviceTimestamp.format('MMYY')
        var serviceDate = serviceTimestamp.format('DD')
        var currentDate = moment().format('MMDDYY')

        var action = batchRowValues[i][0]

        if (action != 'Drop Off' && action != 'Pick Up') {
          continue
        }

        rows[i] = {}
        rows[i]['id'] = i
        rows[i]['serviceDate'] = timeColumn
        rows[i]['serviceDate'] = timeColumn
        rows[i]['action'] = action
        rows[i]['rowId'] = i

        var name = ''

        // default is pick up
        if (action == 'Pick Up') {
          name = batchRowValues[i][8].trim()
          if (name == '') {
            name = batchRowValues[i][22].trim()
          }
        }

        // check if drop off
        if (action == 'Drop Off') {
          name = batchRowValues[i][9].trim()
          if (name == '') {
            name = batchRowValues[i][23].trim()
          }
        }

        if (name.includes(',')) {
          var moreNames = name.split(',')
          name = moreNames[0].trim()
          if (name == '') name = moreNames[1].trim()
          if (name == '') continue
          for (let n = 1; n < moreNames.length; n++) {
            if (moreNames[n].trim() != '') {
              var next = batchRowValues.length
              if (action == 'Pick Up') {
                batchRowValues[next] = []
                batchRowValues[next][0] = 'Pick Up'
                batchRowValues[next][8] = moreNames[n].trim()
                batchRowValues[next][28] = timeColumn
                batchRowValues[next][100] = true
              } if (action == 'Drop Off') {
                batchRowValues[next] = []
                batchRowValues[next][0] = 'Drop Off'
                batchRowValues[next][9] = moreNames[n].trim()
                batchRowValues[next][28] = timeColumn
                batchRowValues[next][100] = true
              }
            }
          }
        }
        rows[i]['fullName'] = name

        var parsedName = this.parseName(name)

        if (this.state.db.items[name]) {
          rows[i]['medicaidRecipientNumber'] = this.state.db.items[name].medicaidNumber
          var lastName = this.state.db.items[name].lastName
          var firstName = this.state.db.items[name].firstName
          var serviceCode = this.state.db.items[name].serviceCode
          console.log(this.state.db.items[name])
        } else {
          rows[i]['medicaidRecipientNumber'] = ''
          var lastName = ''
          var firstName = ''
          var serviceCode = ''
        }

        rows[i]['firstName'] = firstName
        rows[i]['lastName'] = lastName

        exportFields[i] = []
        exportFields[i]['monthYear'] = serviceMonthYear
        exportFields[i]['currentDate'] = currentDate
        exportFields[i]['formNumber'] = '2'
        exportFields[i]['recipientLastName'] = this.pad(lastName.substr(0, 5), 5)
        exportFields[i]['recipientFirstName'] = firstName.substr(0, 1)
        exportFields[i]['contractNumber'] = '1813069'
        exportFields[i]['serviceDate'] = serviceDate
        exportFields[i]['medicaidRecipientNumber'] = rows[i]['medicaidRecipientNumber']
        exportFields[i]['serviceCode'] = serviceCode
        exportFields[i]['unitsOfService'] = '0001' // figure out
        exportFields[i]['otherSourceCode'] = ' '
        exportFields[i]['otherSourceAmount'] = '       '
        exportFields[i]['groupsSize'] = '01' // bring up
        exportFields[i]['serviceCounty'] = '18'
        exportFields[i]['UCR'] = '0001990'
        exportFields[i]['optionalRef'] = '         '
        exportFields[i]['staffSize'] = '01'
      }

      var compactRows = rows.filter((item) => {
        return item !== undefined
      })

      this.setState({
        data: compactRows,
        allRows: compactRows,
        exportFields: exportFields
      })

      this.myCallback()
    });
  }

  toggleRow(id) {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id] = !this.state.selected[id];
    this.setState({
      selected: newSelected,
      selectAll: 2
    });
  }

  toggleSelectAll() {
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.data.forEach(original => {
        newSelected[original.id] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0
    });
  }

  render() {
    const columns = [{
      id: "checkbox",
      accessor: "",
      Cell: ({ original }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={this.state.selected[original.id] === true}
            onChange={() => {this.toggleRow(original.id)}}
          />
        );
      },
      Header: x => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={this.state.selectAll === 1}
            ref={input => {
              if (input) {
                input.indeterminate = this.state.selectAll === 2;
              }
            }}
            onChange={() => {this.toggleSelectAll()}}
          />
          );
        },
        sortable: false,
        width: 45
      }, {
        Header: 'rowID',
        accessor: 'rowId',
        show: false
      }, {
        Header: 'Action',
        accessor: 'action',
      }, {
        Header: 'Full Name',
        accessor: 'fullName',
      }, {
        Header: 'First Name',
        accessor: 'firstName',
      }, {
        Header: 'Last Name',
        accessor: 'lastName',
      }, {
        Header: 'Service Date',
        accessor: 'serviceDate',
      }, {
        Header: 'Medicaid Number',
        accessor: 'medicaidRecipientNumber',
      }
    ];

    return (
      <div>
        <div className="row" style={styles.mainRow}>
          <div className="col-md-6">
            <Button form="true" onClick={this.downloadTxtFile}>Export Selected</Button>
          </div>
          <div className="col-md-6 text-right">
            <WeekPicker callbackFromParent={this.myCallback}></WeekPicker>
          </div>
        </div><br />
        <ReactTable defaultSorted={[{id: 'rowId', desc: true}]} style={styles.container} data={this.state.data} columns={columns} />
      </div>
    )
  }
  pad(string, size) {
    var newString = string
    if (size-string.length != 0) {
      newString += ('                ').substr(0, size - string.length)
    }
    return newString
  }

  pad0(string, size) {
    var zeroString = '000000000'
    var newString = zeroString.substring((string + "").length, 4) + string;
    return newString
  }

  getOccurrence(obj, value) {
    obj.reduce(function(sums,entry){
     sums[entry] = (sums[entry] || 0) + 1;
     return sums;
    },{});
  }

  combineUnitsOfService(rows) { // takes array of selected checkbox IDs
    // make an object of IDs with full service date to check against each other
    var checks = {}
    var count = {}
    var i = 0

    // loop through the checkboxes
    for (var key in rows) {
      if (!rows.hasOwnProperty(key)) continue

      // grab the full data row from the checkbox ID
      var row = this.state.exportFields[key]
      
      // add full IDs + service date to checks array
      checks[key] = row['medicaidRecipientNumber'] + row['serviceDate'] + row['monthYear']
    }

    var checksKeys = Object.values(checks)
    count = {};

    checksKeys.forEach(function(a) {
      count[a] = (count[a] || 0) + 1;
    });

    var checked = [] // this will hold the initial row of each ride
    var repeats = [] // this will hold rows that match the inital row but are not the initial row (so we can use them to increment the initial rows' units of service but remove the "repeats" later)

    for (var key in checks) { // loop through all the full IDs + service dates
      if (!checks.hasOwnProperty(key)) continue

      if (checked.includes(checks[key]) === false) { // if it's not already in the checked array, add it to the checked array
        checked[key] = checks[key]
      } else { // if it already exists in the checked array, add it to the repeats array
        repeats[key] = checks[key]
      }
    }

    var combinedUnitsOfService = {} // this will hold the final array with accurate units of service

    for (var key in repeats) { // go through the repeats
      checked.map((value, index, array) => {
        // find out which initial row it's associated with because they have the same full medical IDs + service date
        if (repeats[key] == value) {
          // add to the initial row's unit of service each time the above is true
          var units = this.pad0(String(count[checks[key]] / 2), 4)

          // update the export fields array to reflect the new units of service for initial rides
          this.setState({
            exportFields: this.state.exportFields.map((value, index2, array) => {
              if (index2 === index) {
                value['unitsOfService'] = units
                combinedUnitsOfService[index] = true // add it to the "clean" array of rows with accurate combined units of service
              }
            })
          })
        }
      })
    }

    // now go through the checked ones
    for (var key in checked) {
      if (repeats.includes(checked[key]) === false) {
        // if the row isn't a repeat (it's on its own) add it to the "clean" array too, just don't up its units of service as we did in the above loop
        combinedUnitsOfService[key] = true
      }
    }

    return combinedUnitsOfService
  }

  downloadTxtFile() {
    var texts = []
    var exportText = ''
    var i = 0
    var row = []

    this.setState({ state: this.state });

    var combinedUnitsOfService = this.combineUnitsOfService(this.state.selected)

    for (var key in combinedUnitsOfService) {
      if (!combinedUnitsOfService.hasOwnProperty(key)) continue;
      if (combinedUnitsOfService[key] === true) {
        var row = this.state.exportFields[key]

        texts[i] = row['monthYear'] + row['currentDate'] + row['formNumber'] + row['medicaidRecipientNumber'] + row['recipientLastName'] + row['recipientFirstName'] + row['contractNumber'] + row['serviceDate'] + row['serviceCode'] + row['unitsOfService'] + row['otherSourceCode'] + row['otherSourceAmount'] + row['groupsSize'] + row['serviceCounty'] + row['UCR'] + row['optionalRef'] + row['staffSize']
        i++
      }
    }

    for (var i = 0; i < texts.length; i++) {
      if (texts[i]) {
        var exportText = exportText + texts[i] + "\n"
      }
    }
    var moment = require('moment');
    var element = document.createElement("a");
    var file = new Blob([exportText], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "M1813069"+moment().format('MMDDYYYY')+".txt";
    element.click();
  }

  myCallback = (dataFromChild) => {
    var moment = require('moment');
    var data = this.state.allRows.filter((value, index, array) => {
      var serviceDate = value.serviceDate
      if (!dataFromChild) {
        var startDate = moment().subtract('days', 7)
        var endDate = moment()
      } else {
        var startDate = dataFromChild.startDate
        var endDate = dataFromChild.endDate
      }

      if (moment(serviceDate).isAfter(startDate) && moment(serviceDate).isBefore(endDate)) return true
    })
    this.setState({data})
  }

  getMedicaidNumbers() {
    return Firebase.auth().onAuthStateChanged((user) => {
      var record = Firebase.database().ref('users')
      var number = record.once('value').then((snapshot) => {
        this.setState({db: { items: snapshot.val() }})
        this.getRows()
      })
    })
  }
}

var styles = {
  container: {
    background: '#fff'
  }
}

export default DataTable;